import React from 'react';

interface Props {
  width: string;
  height: string;
  fill?: string;
  stroke?: string;
}

const Ribbon: React.FC<Props> = ({
  width,
  height,
  fill = 'fill-white',
  stroke = 'stroke-white',
}) => (
  <span
    className={`inline-block flex-none ${width} ${height} ${fill} ${stroke}`}
    role="img"
    aria-label="Ribbon"
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 77 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65.9853 29.688C65.9853 44.9796 53.5888 57.3761 38.2971 57.3761C23.0054 57.3761 10.6091 44.9796 10.6091 29.688C10.6091 14.3963 23.0054 2 38.2971 2C53.5888 2 65.9853 14.3963 65.9853 29.688Z"
        strokeWidth="3.43"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.4302 14.6548L43.2442 22.3271C43.3295 22.5211 43.4647 22.689 43.6361 22.8137C43.8075 22.9384 44.009 23.0153 44.2199 23.0366L52.6904 24.3228C52.9326 24.3543 53.1608 24.4541 53.3483 24.6105C53.5358 24.767 53.675 24.9735 53.7494 25.2061C53.8238 25.4387 53.8305 25.6877 53.7686 25.9239C53.7067 26.1602 53.5789 26.3739 53.4 26.5402L47.1468 32.4829C47.0536 32.6608 47.0049 32.8586 47.0049 33.0594C47.0049 33.2603 47.0536 33.4581 47.1468 33.636L48.3443 42.0622C48.3954 42.3057 48.3747 42.5589 48.2848 42.7909C48.1948 43.023 48.0395 43.2239 47.8377 43.3694C47.6358 43.515 47.396 43.5988 47.1474 43.6108C46.8989 43.6228 46.6522 43.5624 46.4373 43.437L38.898 39.4456C38.7029 39.3574 38.4912 39.3119 38.2772 39.3119C38.063 39.3119 37.8514 39.3574 37.6563 39.4456L30.117 43.437C29.9021 43.5624 29.6553 43.6228 29.4068 43.6108C29.1583 43.5988 28.9185 43.515 28.7166 43.3694C28.5147 43.2239 28.3594 43.023 28.2695 42.7909C28.1796 42.5589 28.1589 42.3057 28.21 42.0622L29.6292 33.636C29.6902 33.4404 29.7019 33.2327 29.6632 33.0316C29.6245 32.8304 29.5366 32.6419 29.4074 32.4829L23.1543 26.4958C22.9885 26.3278 22.872 26.1174 22.8178 25.8876C22.7636 25.6578 22.7737 25.4175 22.847 25.1931C22.9202 24.9687 23.0539 24.7687 23.2333 24.6152C23.4126 24.4617 23.6308 24.3605 23.8639 24.3228L32.3344 23.081C32.5453 23.0596 32.7467 22.9827 32.9182 22.8581C33.0896 22.7334 33.2248 22.5655 33.3101 22.3714L37.1241 14.6991C37.2261 14.4819 37.3869 14.2976 37.5883 14.1671C37.7896 14.0365 38.0234 13.9648 38.2634 13.9602C38.5033 13.9556 38.7397 14.0182 38.9459 14.141C39.1521 14.2637 39.3199 14.4417 39.4302 14.6548Z"
        strokeWidth="3.43"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3129 43.2695L2 64.5961L15.3044 61.0313L18.8693 74.3357L29.5526 55.8319"
        strokeWidth="3.43"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.3122 43.2695L74.625 64.5961L61.3206 61.0313L57.7558 74.3357L47.0725 55.8319"
        strokeWidth="3.43"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);

export default Ribbon;
